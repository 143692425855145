<template>
  <!-- 开发人员：李长兵 -->
  <div style="height: 100%; padding: 10px" v-loading="loading">
    <el-card class="box-card">
      <i
        class="el-icon-arrow-left"
        @click="fanhui"
        style="
          float: left;
          width: 12px;
          height: 15px;
          margin-right: 20px;
          cursor: pointer;
          color: #888888;
        "
      ></i>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>
          <span style="cursor: pointer" @click="two">{{ content }}</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <span style="cursor: pointer" @click="one">{{ titles }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <span class="title">{{ title }}</span>
    </el-card>

    <el-table
      v-if="$route.query.content != 'Asset'"
      :data="tableData"
      style="width: 100%; margin-bottom: 20px; text-align: center"
      row-key="id"
      default-expand-all
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <template v-for="item in dataList.headLabel">
        <slot :name="item.schemefieldName" :item="item">
          <el-table-column
            :prop="item.schemefieldName"
            :label="item.nameLabel"
            :width="item.schemefieldName === 'name' ? 600 : 'auto'"
          >
            <template slot-scope="scope">
              <span
                style="
                  min-width: 24px;
                  min-height: 12px;
                  border: 1px solid #006dcc;
                  color: #006dcc;
                  font-size: 8px;
                  margin-right: 10px;
                  min-width: 12px;
                  padding: 2px;
                  border-radius: 3px;
                "
                v-if="
                  scope.row.isCurrentRecord !== 'true'
                    ? false
                    : item.schemefieldName === apiname
                    ? true
                    : false
                "
              >
                <!-- 当前 -->
                {{ $t("vue_labenl_normal_current") }}
              </span>

              <span
                :class="
                  item.apiname === 'name' ||
                  item.schemefieldType === 'Y' ||
                  item.schemefieldType === 'M'
                    ? 'color'
                    : ''
                "
                @click="
                  item.apiname === 'name' ||
                  item.schemefieldType === 'Y' ||
                  item.schemefieldType === 'M'
                    ? detail(scope.row, item.apiname)
                    : ''
                "
              >
                {{ scope.row[item.schemefieldName] }}
              </span>
            </template>
          </el-table-column>
        </slot>
      </template>
    </el-table>

    <!-- 开发人员：彭雨萏 -->
    <el-table
      :data="tableData"
      style="width: 100%; margin-bottom: 20px; text-align: center"
      row-key="id"
      :expand-row-keys="idArr"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      v-else
    >
      <template v-for="item in dataList.headLabel">
        <slot :name="item.schemefieldName" :item="item">
          <el-table-column
            :prop="item.schemefieldName"
            :label="item.namelabel"
            :width="item.schemefieldName === 'name' ? 600 : 'auto'"
          >
            <template slot-scope="scope">
              <span
                style="
                  width: 24px;
                  height: 12px;
                  border: 1px solid #006dcc;
                  color: #006dcc;
                  font-size: 8px;
                  margin-right: 10px;
                  min-width: 12px;
                  padding: 2px;
                  border-radius: 3px;
                "
                v-if="
                  scope.row.isCurrentRecord !== 'true'
                    ? false
                    : item.schemefieldName === apiname
                    ? true
                    : false
                "
              >
                <!-- 当前 -->
                {{ $t("vue_labenl_normal_current") }}
              </span>

              <span
                :class="
                  item.apiname === 'name' ||
                  item.schemefieldType === 'Y' ||
                  item.schemefieldType === 'M'
                    ? 'color'
                    : ''
                "
                @click="
                  item.apiname === 'name' ||
                  item.schemefieldType === 'Y' ||
                  item.schemefieldType === 'M'
                    ? detail(scope.row, item.apiname)
                    : ''
                "
              >
                {{ scope.row[item.schemefieldName] }}
              </span>
            </template>
          </el-table-column>
        </slot>
      </template>
      <!-- 操作列 -->
      <el-table-column
        :label="''"
        :align="'center'"
        style="text-align: center"
        :width="'80'"
      >
        <template slot-scope="scope">
          <el-popover
            placement="bottom-end"
            popper-class="pop_small"
            trigger="hover"
            :visible-arrow="false"
          >
            <svg
              class="icon"
              slot="reference"
              style="cursor: pointer; width: 14px"
            >
              <use href="#icon-xiala"></use>
            </svg>
            <!-- <img
              :src="xialiaImg"
              slot="reference"
              style="cursor: pointer; width: 14px"
            /> -->
            <ul>
              <li @click="handleTableAction(scope, 'scopeEdit')">
                <span class="cursor-pointer">
                  <!-- 编辑 -->
                  {{ $t("label.modify") }}
                </span>
              </li>
              <li @click="handleTableAction(scope, 'scopeDelete')">
                <span class="cursor-pointer">
                  <!-- 删除 -->
                  {{ $t("label.delete") }}
                </span>
              </li>
              <!-- <li @click="handleTableAction(scope, '更改所有人')">
                <span class="cursor-pointer"> 更改所有人 </span>
              </li> -->
            </ul>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>

    <!-- 新建 编辑资产 -->
    <create-edit-obj
      ref="createEditObj"
      prefix="s42"
      :id="checkedId"
      object-api="Asset"
      object-name="资产"
      :type="'active'"
      @save="save"
    ></create-edit-obj>

    <!-- 删除数据和解除锁定 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :title="$t('label.delete')"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <span style="font-size: 16px; font-weight: bold">
        <!-- 确认要删除这条数据吗 -->
        {{ $t("label.projectManagement.you.want.to.delete.this.data") }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">
          <!-- 取消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
        <el-button @click="confirm" type="primary" size="mini">
          <!-- 确认 -->
          {{ $t("label_chatter_ok") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import createEditObj from "./components/create-edit-obj.vue"; //新建 编辑

import {
  getAssetBOMList, //获取资产BOM列表
  getPermissionById, //获取对象记录权限
  deleteBom, // 删除记录
} from "./api.js";
import { GetDataHierarchyDisplay } from "./api.js";
export default {
  components: {
    createEditObj,
  },
  created() {
    if (this.$route.query.content === "Contact") {
      this.title =
        this.$i18n.t("label.contact") + this.$i18n.t("label.hierarchy.diagram"); //)"联系人层级结构";
      this.getInfo();
    } else if (this.$route.query.content === "Account") {
      this.title =
        this.$i18n.t("label.weixin..xsld.kehu") +
        this.$i18n.t("label.hierarchy.diagram"); //"客户层级结构";
      this.getInfo();
    } else if (this.$route.query.content === "campaign") {
      this.title =
        this.$i18n.t("label.marketingactivity") +
        this.$i18n.t("label.hierarchy.diagram"); //"市场活动层级结构";
      this.getInfo();
    } else if (this.$route.query.content === "Asset") {
      this.title = this.$i18n.t("label.asset") + "BOM"; //"资产BOM"
      this.getAssetBOMList();
    }
  },
  data() {
    return {
      dialogVisible: false, //删除弹框
      checkedId: "", // 选中的id
      titles: "",
      title: this.$i18n.t("label.account.contact"), //"客户层级结构",
      id: this.$route.query.ids,
      type: this.$route.query.type,
      content: this.$route.query.content,
      tableData: [],
      dataList: [],
      apiname: "",
      loading: false,
      idArr: [],
    };
  },
  methods: {
    // 编辑保存的回调
    save() {
      this.getAssetBOMList();
    },
    // 删除
    async confirm() {
      let params = {
        id: this.checkedId,
        objectApi: "Asset",
      };
      await deleteBom(params);
      this.getAssetBOMList();
      this.dialogVisible = false;
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    // 操作按钮
    async handleTableAction(item1, item2) {
      this.checkedId = item1.row.id;
      let params = {
        id: this.id,
      };
      let getPermissionByIdRes = await getPermissionById(params);
      if (item2 == "scopeEdit") {
        if (getPermissionByIdRes.data.isEdit == false) {
          this.$message({
            // 无编辑权限 batch.edit.permission
            // 您没有权限执行此操作 label.weixin.donot.permission
            message: this.$i18n.t("label.weixin.donot.permission"),
            type: "warning",
          });
        } else {
          // 编辑弹框
          this.$nextTick(() => {
            this.$refs.createEditObj.edit();
          });
        }
      } else if (item2 == "scopeDelete") {
        if (getPermissionByIdRes.data.isEdit == false) {
          this.$message({
            // 您没有删除权限 label.donot.delete
            // 您没有权限执行此操作 label.weixin.donot.permission
            message: this.$i18n.t("label.weixin.donot.permission"),
            type: "warning",
          });
        } else {
          // 删除弹框
          this.dialogVisible = true;
        }
      }
    },
    fanhui() {
      this.$router.go(-1);
    },
    //获取资产BOM列表
    async getAssetBOMList() {
      this.loading = true;
      let params = {
        assetId: this.id,
      };
      let getAssetBOMListRes = await getAssetBOMList(params);
      this.titles = getAssetBOMListRes.data.currentRecordName;
      this.content = getAssetBOMListRes.data.objLabel;
      this.dataList = getAssetBOMListRes.data;
      getAssetBOMListRes.data.tableData;
      this.deleteChildren(getAssetBOMListRes.data.tableData);
      this.tableData = getAssetBOMListRes.data.tableData;
      // el-tree显示指定级
      this.tableData.forEach((item) => {
        this.idArr.push(item.id);
      });
      this.loading = false;
    },
    async getInfo() {
      this.loading = true;
      let params = {
        id: this.id,
        type: this.type,
      };
      let res = await GetDataHierarchyDisplay(params);

      if (res.result) {
        this.dataList = res.data;
        this.apiname = res.data.headLabel[0].apiname;
        this.titles = res.data.currentRecordName;
        this.content = res.data.objLabel;
        res.data.tableData;
        this.deleteChildren(res.data.tableData);
        this.tableData = res.data.tableData;
        this.loading = false;
      }
    },
    one() {
      this.$router.go(-1);
    },
    two() {
      this.$router.go(-2);
    },
    deleteChildren(arr) {
      let childs = arr;
      for (let i = childs.length; i--; i > 0) {
        Object.keys(childs[i]).forEach((v) => {
          if (v.indexOf("ccname") !== -1) {
            this.dataList.headLabel.forEach((item) => {
              let res = v.replace("ccname", "");
              if (item.schemefieldName === res) {
                item.schemefieldName = item.schemefieldName + "ccname";
              }
            });
          }
        });
        if (childs[i].children) {
          if (childs[i].children.length) {
            this.deleteChildren(childs[i].children);
          }
        }
      }
      return arr;
    },
    detail(val, key) {
      let id = "";
      if (key === "name") {
        id = val.id;
      } else {
        id = val[key];
      }

      this.$router.push({
        path: `/commonObjects/detail/${id}/DETAIL`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-card {
  background: #f5f5f5;
  border: 0;
  box-shadow: none !important;
  border: 1px solid #dedcda;

  .el-card__body {
    padding: 20px 20px 2px 20px;
  }
}

::v-deep .el-breadcrumb {
  position: relative;
  top: -14px;

  ::v-deep .el-breadcrumb__inner {
    width: 24px;
    height: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #006dcc;
    line-height: 18px;

    &:hover {
      color: #005fb2;
    }
  }

  ::v-deep .el-breadcrumb__separator {
    margin: 0;
  }
}

.title {
  width: 98px;
  height: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #222222;
  line-height: 15px;
  margin-left: 33px;
  position: relative;
  top: -8px;
}

::v-deep .el-table {
  height: 100%;
  margin-top: 10px;
  border: 1px solid #dedcda !important;

  .is-leaf {
    background: #fafaf9;
    border-right: 1px solid #dedcda;

    .cell {
      text-align: left;
      font-size: 14px;
      font-weight: bold;
      color: #3e3e3c;
      line-height: 21px;
      white-space: nowrap !important;
    }
  }
}

::v-deep .el-table__row {
  .cell {
    margin-left: 3px;
    color: #080707;
    white-space: nowrap !important;
  }
}

.color {
  color: #006dcc;
  cursor: pointer;
}

::v-deep .el-table__expand-icon {
  color: #888888;
}

// ::v-deep .el-table__indent{
//   padding-left:0 !important;
// }
// ::v-deep .el-table__header{
//   width: 1728px !important;
//   table-layout:auto !important;
// }
// 表格操作列样式
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
}
.pop_small li span {
  padding-left: 10px;
}
.pop_small li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
</style>
